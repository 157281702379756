<script lang="ts"  setup>
import Banner from "@/components/auth/Banner.vue";

const options = {
  background: {
    color: {
      value: 'rgba(0,28,167,0.6)'
    }
  },
  fpsLimit: 120,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: 'push'
      },
      onHover: {
        enable: true,
        mode: 'repulse'
      },
      resize: true
    },
    modes: {
      bubble: {
        distance: 400,
        duration: 2,
        opacity: 0.8,
        size: 40
      },
      push: {
        quantity: 4
      },
      repulse: {
        distance: 150,
        duration: 0.4
      }
    }
  },
  particles: {
    links: {
      enable: true
    },
    move: {
      enable: true
    },
    number: {
      value: 150
    },
    opacity: {
      value: { min: 0.3, max: 1 }
    },
    shape: {
      type: ["circle", "square", "triangle", "polygon"],
      options: {
        polygon: [
          {
            sides: 18
          },
          {
            sides: 15
          },
          {
            sides: 89
          }
        ]
      }
    },
    size: {
      value: { min: 2, max: 6 }
    }
  },
  detectRetina: true
};

async function particlesInit() {
  console.log("teste");
}
</script>
<template>

  <vue-particles
      id="tsparticles"
      :particlesInit="particlesInit"
      :options="options"
  />
  <div style="height: 100dvh" class="d-flex align-center auth-wrapper">
    <v-container style="height: inherit" class="d-flex">
      <v-row justify="center" class="align-center" no-gutters>
        <v-col cols="12" sm="12" md="8" lg="5" xl="5">
          <v-card>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
                <router-view />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
