import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes";
import appConfigs from "@/app/appConfigurations";
import { fakeBackendService } from "@/app/http/httpServiceProvider";
import { useRouter } from "vue-router";
import {useAuthStore} from "@/stores/auth.store";
import {httpService} from "@/app/http/apiServiceProvider";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const title = "Geoblue Soluções Ambientais";

router.beforeEach((to, from, next) => {
  const auth = appConfigs.auth;
  const router = useRouter();

  const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + " | " + title || title;
  }

  const isAuthRequired = to.meta.authRequired;

  if (!isAuthRequired) {
    return next();
  }
  if (auth === "jwt") {
    const user = httpService.getUser();

    const isUserLoggedIn = Object.keys(user).length > 0;

    if (isAuthRequired && isUserLoggedIn) {
      next();
    } else {
      router.push("/login");
    }
  } else {
    next();
  }
  if (auth === "fakebackend") {
    const user = fakeBackendService.getUser();
    const isUserLoggedIn = Object.keys(user).length > 0;

    if (isAuthRequired && isUserLoggedIn) {
      next();
    } else {
      router.push("/login");
    }
  } else {
    next();
  }
});

export default router;
